<template>
  <div class="fragment">

    <template v-if="binsSortedByUser.length > 0">
      <div class="order-bins">
        <template v-for="(item, index) in binsSortedByUser">

            <div class="order-bins__section" :key="index">
              <div class="order-bins__section-name">{{item.name}}</div>
              <div class="order-bins__list">
                <OrderBinItem
                    v-for="(bin, binIndex) in item.bins"
                    :key="binIndex"
                    :bin="bin"
                    @editCell="changeOrderBinPopup(true, bin)"
                    @removeElement="removeItem(bin.id)"
                    @printPdf="$emit('print', {type: 'pdf', ids: [bin.id]})"
                    @printDocx="$emit('print', {type: 'docx', ids: [bin.id]})"
                />
              </div>
            </div>

        </template>
      </div>
    </template>

    <OrderBinPopup
        v-if="isModalOrderBinPopup"
        :modalOrderBinId="modalOrderBinId"
        :type="'edit'"
        @close="changeOrderBinPopup(false)"
        @reload="$emit('reload')"
    />

  </div>
</template>

<script>
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import OrderBinPopup from "../../../../popups/OrderBinPopup/OrderBinPopup";
  import OrderBinItem from "./OrderBinItem/OrderBinItem";


  export default {
    name: "NewPaymentsTable",

    components: {
      OrderBinItem,
      OrderBinPopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    computed: {
      binsSortedByUser() {
        let users = []
        this.$store.getters.getOrderBin.forEach(item => {
          let findUser = users.find(userItem => {
            return userItem.id === item.user?.id
          })
          if (!findUser && item?.user) {
            users.push({
              id: item.user.id,
              name: item.user?.user_personal_contact?.user_full_name,
              bins: []
            })
          }
        })

        users.forEach(item => {
          this.$store.getters.getOrderBin.forEach(binItem => {
            if (item.id === binItem?.user?.id) {
              item['bins'].push(binItem)
            }
          })

          item.bins = item.bins.sort((a, b) => {
            let aIsInt = /^[+-]?\d+$/.test(a.name)
            let bIsInt = /^[+-]?\d+$/.test(b.name)
            if (aIsInt && bIsInt) {
              if (+a.name > +b.name) {
                return 1
              }
              else if (+a.name < +b.name) {
                return -1
              }
              return 0
            }
            else if (aIsInt && !bIsInt) {
              return -1
            }
            else if (!aIsInt && bIsInt) {
              return 1
            }
            else if ((a.name.toLowerCase().indexOf(b.name.toLowerCase()) || b.name.toLowerCase().indexOf(a.name.toLowerCase())) && a.name.length > b.name.length) {
              return 1
            }
            else if ((a.name.toLowerCase().indexOf(b.name.toLowerCase()) || b.name.toLowerCase().indexOf(a.name.toLowerCase())) && a.name.length < b.name.length) {
              return -1
            }
            else {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en')
            }

          })
        })

        return users
      }
    },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        hideEditField: false,

        show1: false,
        show2: false,

        isModalOrderBinPopup: false,
        modalOrderBinId: 0,
      }
    },

    methods: {
      changeOrderBinPopup(val, item = null) {
        this.isModalOrderBinPopup = val
        if (item) {
          this.modalOrderBinId = item.id
        }

      },

      removeItem(id){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteOrderBin', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let orderBin = this.$store.getters.getOrderBin
              let deletedIndex = orderBin.findIndex(item => {
                return item.id === id
              })
              orderBin.splice(deletedIndex, 1)

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },
    }

  }

</script>

<style scoped lang="scss">
  .order-bins {
    padding-bottom: 50px;
    padding-top: 30px;

    &__section {
      padding-bottom: 40px;
    }

    &__section-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #BBAD9C;
      margin-bottom: 12px
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      border-radius: 2px;
    }
  }
</style>
