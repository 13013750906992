import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {User} from "../../../globalModels/User";

export function OrderBin() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
    User: new User(),
    description: '',
    mixed: false,
    historyOfChanged: null,
  }

  this.validation = {
    id: false,
    name: false,
    description: false,
  }

  this.validationTranslate = {
    id: '',
    name: '',
    description: '',
  }

  this.validationTxt = {
    id: false,
    name: false,
    description: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getMixed = () => {
    return this.data.mixed
  }
  this.getDescription = () => {
    return this.data.description
  }
  this.getHistoryOfChanged = () => {
    return this.data.historyOfChanged
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setMixed = (val) => {
    this.data.mixed = val
  }
  this.setDescription = (val) => {
    this.data.description = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }
}

/**
 * Global Setters
 */

OrderBin.prototype.setItem = function (item) {

  this.setId(item?.id)
  this.setName(item.name)
  this.setMixed(item.mixed === 1)
  if (item?.user) {
    this.data.User.setUser(item.user)
  }
  this.setHistoryOfChanged(item?.activityUUID)
}

/**
 * Prepare Data
 */

OrderBin.prototype.prepareSave = function () {
  return {
    'name': this.getName(),
    'user_id': this.data.User.getUserId(),
    'mixed': this.getMixed() ? 1 : 0,
  }
}

/**
 * Validations
 */

OrderBin.prototype.firstValidationUser = function () {

  let validationItems = {
    name: this.getName(),
  }

  let validationOptions = {
    name: {type: ['latin','empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
