<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div v-if="type === 'create'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderBin_NewBinPopup'])"></span>
        {{$t('orderBin_NewBinPopup.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderBin_EditBinPopup'])"></span>
        {{$t('orderBin_EditBinPopup.localization_value.value')}}
      </div>
<!--      <div class="site-link brown text-decoration-underline mb-3 mt-2">-->
<!--        <div v-if="isAdmin && OB.data.historyOfChanged"-->
<!--             class="mb-3"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>-->
<!--          <div class="site-link text-decoration-underline btn-style brown"-->
<!--               @click="historyOfChangesFunc('orderBin', OB.data.historyOfChanged)">-->
<!--            {{$t('common_CheckHistory.localization_value.value')}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderBin_name'])"></span>
            <DefaultInput
                :label="$t('orderBin_name.localization_value.value')"
                v-bind:class="{'ui-no-valid': OB.validation.name}"
                :error="OB.validation.name"
                :errorTxt="$t(`${OB.validationTranslate.name}.localization_value.value`)"
                v-model="OB.data.name"
            />
          </div>
        </div>
        <div class="custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderBin_mixed'])"></span>
              <DefaultCheckbox
                  :label="$t('orderBin_mixed.localization_value.value')"
                  v-model="OB.data.mixed"
              />
          </div>
        </div>

<!--        <div class="custom-row">-->
<!--          <div class="custom-col"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <span class="admin-edit" @click="editTranslate(['orderBin_description'])"></span>-->
<!--            <TextareaDefault-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel','common_save'])"></span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
             :value="$t('common_save.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {OrderBin} from "../../models/OrderBin";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";

  export default {
    name: "OrderBinPopup",
    components: {
      DefaultCheckbox,
      // TextareaDefault,
      DefaultInput,
      Modal,
      MainButton,
    },

    props: {
      type: {
        type: String,
        default: 'create'
      },
      modalOrderBinId: Number,
    },

    data() {
      return {
        OB: new OrderBin(),
      }
    },

    mounted() {
      if (this.type !== 'create') {
        this.$store.dispatch('getOrderBin', this.modalOrderBinId).then(response => {
          let data = this.getRespData(response)
          this.OB.setItem(data)
          if (!this.OB.data.User.getUserId()) {
            let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
            this.OB.data.User.setUser(userData)
          }
        }).catch(error => this.createErrorLog(error))
      }
      if (this.type === 'create') {
        let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
        this.OB.data.User.setUser(userData)
      }
    },

    methods: {
      save() {
        if(!this.OB.firstValidationUser()) return

        let data = this.OB.prepareSave()

        let typeDispatch = 'createOrderBin'

        if (this.type !== 'create') {
          typeDispatch = 'updateOrderBin'
          data = {
            data: data,
            id: this.modalOrderBinId
          }
        }

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              if (this.type === 'create') {
                this.openNotify('success', 'common_notificationRecordCreated')
              } else {
                this.openNotify('success', 'common_notificationRecordChanged')
              }
              this.$emit('close')
              this.$emit('reload')

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>
