<template>
  <div class="order-bins__item"
       @mouseover="showBtn = true"
       @mouseleave="showBtn = false"
  >
    <div class="order-bins__item-name">
      {{bin.name}}
    </div>
    <template v-if="showBtn">
      <div class="order-bins__item-btn">
        <div class="order-bins__item-btn-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['warehouseStorage_print'])"></div>
          <LinkButton
              :value="$t('common_print.localization_value.value')"
              :type="'print'"
              @click.native="$emit('printDocx')"
          />
        </div>
        <div class="order-bins__item-btn-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['warehouseStorage_print'])"></div>
          <LinkButton
              class="white-space-nowrap"
              :value="$t('orderBin_thermalPrint.localization_value.value')"
              :type="'print'"
              @click.native="$emit('printPdf')"
          />
        </div>
        <div class="order-bins__item-btn-i"
             v-if="checkOwnership()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
          <LinkButton
              :value="$t('common_edit.localization_value.value')"
              :type="'edit'"
              @click.native="$emit('editCell')"
          />
        </div>
        <div class="order-bins__item-btn-i"
             v-if="checkOwnership()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
          <LinkButton
              :value="$t('common_delete.localization_value.value')"
              :type="'delete'"
              @click.native="$emit('removeElement')"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
export default {
  name: "OrderBinItem",
  components: {LinkButton},
  props: {
    bin: Object,
  },
  data() {
    return {
      showBtn: false
    }
  },

  methods: {
    checkOwnership() {
      return (this.bin?.user?.id === this.$store.getters.GET_COMMON_AUTHORIZED.user.id)
    }
  }
}
</script>

<style scoped lang="scss">
.order-bins {
  &__item {
    width: 68px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F4EE;
    border: 1px solid #8F7A61;
    border-top: 0;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: -1px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      width: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background: #8F7A61;
    }
  }

  &__item-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    word-break: break-all;
  }

  &__item-btn {
    position: absolute;
    top: 100%;
    left: 20%;
    transform: translateX(-10%);
    z-index: 1;
    background: white;
    padding: 10px 15px 0;
    display: flex;
    box-shadow: 2px 2px 10px -6px black;

    &-i {
      padding-bottom: 10px;
      padding-left: 12px;
      padding-right: 12px;
      position: relative;
    }
  }
}
</style>