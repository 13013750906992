<template>
  <OrderBinTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @print="print"
  />
</template>

<script>
  import OrderBinTableAdmin from "./OrderBinTableAdmin/OrderBinTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {PERMISSIONS} from "../../../../../staticData/permissionsStatic";

  export default {
    name: "OrderBinTable",

    components: {
      OrderBinTableAdmin,
    },

    mixins: [routeFilter],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      loadUserAuthorizedData: function () {
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getOrderBinForPage,
        page: 1,
        onePage: 1,

        filterIp: '',
        filterCity: '',
        // filterRegion: '',
        filterCountry: '',
        filterDate: [],

        filterUserId: '',
        filterUserName: '',

        countFilterParams: 0,
        filterGetParams: {},

      }
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    methods: {
      checkTabPermission() {

        if(!this.isAdmin || !this._.has(this.currentPermissions, PERMISSIONS.WAREHOUSE_STORAGE_ORDER_BINS_GENERAL)) {
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          ip: this.$route.query.ip,
          city: this.$route.query.city,
          // region: this.$route.query.region,
          country: this.$route.query.country,
          date: this.$route.query.date,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setOrderBinFilter', window.location.search)

        if (next) this.$store.commit('setNextOrderBinPage', true)
        this.$store.dispatch('fetchOrderBin', url).then(() => {
          this.$store.commit('setNextOrderBinPage', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'user_id',
              filterIp: 'ip',
              filterCity: 'city',
              // filterRegion: 'region',
              filterCountry: 'country',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'created_at',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      print({type: formatType, ids: ids}) {

        let data = {
          ids: ids
        }
        this.$store.dispatch('getOrderBinPrint', {data: data, type: formatType}).then((response) => {

          response.status = response?.data?.status || response?.response?.status
          switch (response.status) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              let base64 = this.getRespData(response)['fileContentBase64']
              if (formatType === 'docx') {
                this.globalDownloadBase64File('application/docx', base64,  'bins.docx')
              }
              if (formatType === 'pdf') {
                this.globalOpenPdfBase64(base64)
              }
              break
            }

              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })
      }
    }
  }
</script>

<style scoped>

</style>