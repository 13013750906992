import { render, staticRenderFns } from "./OrderBinTable.vue?vue&type=template&id=08b20840&scoped=true"
import script from "./OrderBinTable.vue?vue&type=script&lang=js"
export * from "./OrderBinTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b20840",
  null
  
)

export default component.exports