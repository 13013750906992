<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'common_print',
          'orderBin_thermalPrint',
          'common_AddNew',
        ])"></div>

      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
<!--        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />-->
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
<!--        <div class="btn-left-block-i">-->
<!--          <DefaultCheckbox-->
<!--              :label="$t('common_selectAll.localization_value.value')"-->
<!--              @input="(val) => $emit('selectAll', val)"-->
<!--          />-->
<!--        </div>-->
      </div>

      <div class="btn-right-block">
        <MainButton class="btn-fit-content btn-right-block-i mr-3"
                    :value="$t('common_print.localization_value.value')"
                    @click.native="$emit('print', {type: 'docx', ids: []})"
        >
        </MainButton>
        <MainButton class="btn-fit-content btn-right-block-i mr-3"
                    :value="$t('orderBin_thermalPrint.localization_value.value')"
                    @click.native="$emit('print', {type: 'pdf', ids: []})"
        >
        </MainButton>
        <MainButton class="btn-fit-content btn-right-block-i"
                    :value="$t('common_AddNew.localization_value.value')"
                    :ico="'ico'"
                    @click.native="changeOrderBinCreatePopup(true)"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <OrderBinPopup
        v-if="isModalOrderBinCreatePopup"
        @close="changeOrderBinCreatePopup(false)"
        @reload="$emit('reload')"
    />

  </div>
</template>

<script>
//import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
// import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
import OrderBinPopup from "../../../../popups/OrderBinPopup/OrderBinPopup";
export default {
  name: "OrderBinHead",
  components: {
    OrderBinPopup,
    MainButton,
    PlusIconSVG,
    //ToggleFilterButton,
    // DefaultCheckbox,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
  },

  created() {

  },

  data() {
    return {
      isModalOrderBinCreatePopup: false,
    }
  },



  methods: {

    selectAll(val) {
      this.$emit('selectAll', val)
    },

    changeOrderBinCreatePopup(val){
      this.isModalOrderBinCreatePopup = val
    },
  }
}
</script>

<style scoped>
@media (min-width: 680px) {
  .btn-left-block{
    flex-wrap: wrap;
    max-width: 50%;
  }

  .btn-left-block-i{
    margin-left: 0!important;
    margin-right: 15px;
  }
}

@media (max-width: 680px){
  .btn-right-block{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .btn-right-block .btn-right-block-i{
    /*margin-top: 15px;*/
    margin-right: 0!important;
    min-width: 150px;
    margin-top: 6px;
  }

  .btn-right-block .btn-right-block-i:last-child{
    margin-top: 0;
  }

  .btn-left-block{
    display: flex;
    flex-direction: column;
    max-width: 50%
  }

  .btn-left-block-i{
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .btn-left-block-i:last-child{
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn-left-block-i--link{
    font-size: 12px;
  }

  .light-bg-block{
    align-items: flex-end;
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}
</style>

