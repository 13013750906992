<template>
  <div>
    <OrderBinHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @print="(params) => $emit('print', params)"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <OrderBinTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @print="(params) => $emit('print', params)"
      />
    </div>
  </div>
</template>

<script>


  import OrderBinHead from "./OrderBinHead/OrderBinHead";
  import OrderBinTable from "./OrderBinTable/OrderBinTable";

  export default {
    name: "OrderBinTableUser",

    components: {
      OrderBinHead,
      OrderBinTable,
    },

    props: {
      typeShops: String,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
